<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">English Section</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="en.title"
                rules="required"
                name="The Title"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="title"
                          name="title"
                          fou
                          @keyup="generateSomeFields"
                          v-model="formData.title">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="en.url"
                rules="required"
                name="The URL"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="URL"
                          name="url"
                          fou
                          v-model="formData.url">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="en.sub_title"
                rules=""
                name="The Sub Title"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="sub title"
                          name="sub_title"
                          fou
                          v-model="formData.sub_title">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <ValidationProvider
            vid="en.name"
            rules="required"
            name="The Name"
            v-slot="{ passed, failed,errors }">
              <fg-select
                name="forum_id"
                size="large"
                filterable
                clearable
                placeholder="Forum"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Forum'"
                :list="builderData.forumList"
                :listItemLabel="'title'"
                :listItemValue="'id'"
                v-model="formData.forum_id">
              </fg-select>
          </ValidationProvider>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="en.register_link"
                rules=""
                name="The Register Link"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="register link"
                          name="register_link"
                          fou
                          v-model="formData.register_link">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-select
                name="speakers"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Speakers"
                :input-classes="'select-default'"
                :label="'Speakers'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.speakers">
              </fg-select>
            </div>
            <div class="col-md-6">
              <fg-select
                name="moderators"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Moderators"
                :input-classes="'select-default'"
                :label="'moderators'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.moderators">
              </fg-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-select
                name="panelists"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select panelists"
                :input-classes="'select-default'"
                :label="'panelists'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.panelists">
              </fg-select>
            </div>
            <div class="col-md-6">
              <fg-select
                name="presenters"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select presenters"
                :input-classes="'select-default'"
                :label="'presenters'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.presenters">
              </fg-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-select
                name="presentersModerators"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select presenters & Moderators"
                :input-classes="'select-default'"
                :label="'presenters & Moderators'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.presentersModerators">
              </fg-select>
            </div>
            <div class="col-md-6">
              <fg-select
                name="ceremonyMasters"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Master of ceremony"
                :input-classes="'select-default'"
                :label="'Master of ceremony'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.ceremony_masters">
              </fg-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-select
                name="sharing_experiences"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Sharing an experience"
                :input-classes="'select-default'"
                :label="'Sharing an experience'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.sharing_experiences">
              </fg-select>
            </div>
            <div class="col-md-6">
              <fg-select
                name="experts"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Experts"
                :input-classes="'select-default'"
                :label="'Experts'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.experts">
              </fg-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-select
                name="welcome_remarks"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Welcome Remarks"
                :input-classes="'select-default'"
                :label="'Welcome Remarks'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.welcome_remarks">
              </fg-select>
            </div>
            <div class="col-md-6">
              <fg-select
                name="opening_remarks"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Opening Remarks"
                :input-classes="'select-default'"
                :label="'Opening Remarks'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.opening_remarks">
              </fg-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-select
                name="keynote_speeches"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Keynote Speeches"
                :input-classes="'select-default'"
                :label="'Keynote Speeches'"
                :list="builderData.speakersList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.keynote_speeches">
              </fg-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="card-label">Date</label>
              <ValidationProvider
                vid="en.date"
                rules="required"
                name="The Date"
                v-slot="{ passed, failed,errors }">
                <fg-input v-model="formData.date"
                          value-format="YYYY-MM-DD HH:MM:SS"
                          :error="failed ? errors[0]: null"
                          type="datetime-local"
                          placeholder="Chose session date"
                >
                </fg-input>
              </ValidationProvider>

            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="card-label">From Time</label>
              <ValidationProvider
                vid="en.from_time"
                rules="required"
                name="The From Time"
                v-slot="{ passed, failed,errors }">
                <fg-input v-model="formData.from_time"
                          :error="failed ? errors[0]: null"
                          value-format="HH:MM:SS"
                          type="time"
                          placeholder="From Time"
                >
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <label class="card-label">To Time</label>
              <ValidationProvider
                vid="en.to_time"
                rules="required"
                name="The To Time"
                v-slot="{ passed, failed,errors }">
                <fg-input v-model="formData.to_time"
                          value-format="HH:MM:SS"
                          :error="failed ? errors[0]: null"
                          type="time"
                          placeholder="To Time"
                >
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group">
            <label>Image</label>
            <el-tooltip placement="right" >
              <div slot="content"> (548 * 548)</div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <single-file-uploader-file-manager
              :preview-width="'200px'"
              :preview-height="'200px'"
              v-model="formData.image"
              :accepted-files="imagesExtensions">
            </single-file-uploader-file-manager>
          </div>
          <div class="form-group">
            <label>Content</label>
            <editor
              v-model="formData.content"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>
          <div class="form-group pt-4">
            <label>Is Active</label>&nbsp;
            <l-switch v-model="formData.is_active">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
          <div class="form-group pt-4">
            <label>Is Highlighted</label>&nbsp;
            <l-switch v-model="formData.is_highlighted">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
          <div class="form-group pt-4">
            <label>Show In Upcoming Sessions</label>&nbsp;
            <l-switch v-model="formData.is_upcoming">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Option, Select, Tooltip} from "element-ui";
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
  import Editor from '@tinymce/tinymce-vue'
  import PaginatedSelect from "../../../components/paginatedSelect";
  import SingleFileUploaderFileManager from "../../../components/FileManager/SingleFileUploaderFileManager";

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      SingleFileUploaderFileManager,
      PaginatedSelect,
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      PrimeUploader,
      ImageUploader,
      PrimeVideoUploader,
      'editor': Editor,
    },
    props: {
      lang: {
        type: String,
        default: 'en'
      },
      editMode: {
        type: Boolean,
        default: false
      },
      builderData:{},
      formData:{
        type: Object,
        default() {
          return {
            title: "",
            sub_title: '',
            url: "",
            date: '',
            register_link:'',
            from_time: '',
            to_time: '',
            speakers: [],
            content: "",
            image: "",
            is_active: true,
            is_upcoming: false,
            is_highlighted: false,
            forum_id: '',
            moderators: [],
            panelists:[],
            presenters:[],
            ceremony_masters:[],
            sharing_experiences:[],
            welcome_remarks: [],
            opening_remarks: [],
            keynote_speeches: [],
            experts:[],
          }
        }
      },
      currentForumId: '',
    },
    data() {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
        imagesExtensions: this.getImagesExtensions(),
      };
    },
    computed: {
      forum() {
        return this.formData.forum_id;
      }
    },
    watch: {
      forum: {
        handler: function (newValue) {
          // if (this.formData.forum_id == ""){
          //   this.formData.speakers = [];
          // }
          this.formData.speakers = [];
          let data ={
            forum_id: this.formData.forum_id
          }
          this.axios.post("sessions/builder", data).then((response) => {
            this.builderData.speakersList = response.data.speakers;
          }).catch((error) => {
            console.error(error);
          })
        },
        deep: true
      },
    },
    mounted() {
      this.formData.forum_id = this.formData.forum_id ? this.formData.forum_id : this.currentForumId;
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      generateSomeFields() {
        let newUrl = '';
        if (this.formData.title) {
          newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "-");
        }
        this.formData.url = newUrl;
      },
    }
  }
</script>
<style>
</style>
